import { createStore } from 'vuex'

import mcc from '../store/modules/mcc'

const store = createStore({
    modules: {
        mcc
    }
})

export default store